import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb19c592"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "textfield-wrapper" }
const _hoisted_2 = ["type", "placeholder", "value", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass({ 'whyte-text': _ctx.passwordEntry })
    }, _toDisplayString(_ctx.label), 3),
    _createElementVNode("input", {
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      class: _normalizeClass({
        'valid-value': _ctx.showSuccess && _ctx.valid,
        uppercased: _ctx.uppercased,
        'password-entry': _ctx.passwordEntry,
      }),
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
      maxlength: _ctx.maxLength,
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
      onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
      autocomplete: "off"
    }, null, 42, _hoisted_2)
  ]))
}