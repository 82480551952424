
import { DialogHTMLAttributes, defineComponent } from "vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  unlockCard,
  updateBusinessCard,
  getIsCardWritable,
  isSignedIn,
  logOut,
} from "@/firebase";
import SignUpView from "@/views/SignUpView.vue";
import TextField from "@/components/TextField.vue";
import LoginTextField from "@/components/LoginTextField.vue";
import ConfirmationAlert from "@/components/ConfirmationAlert.vue";
import "@/assets/mingla-mini-grey.png";
import "@/assets/checkmark-whyte.png";
import "@/assets/mingla.png";

enum OnboardingState {
  Unauthorized,
  EnteringCardCode,
  EnteringCardDetails,
  Finished,
  ErrorSaving,
}

export default defineComponent({
  data() {
    return {
      // View related
      OnboardingState,
      onboardingState: OnboardingState.Unauthorized,
      wrong: false,
      errorMessage: "",
      enteringDetails: false,
      loading: false,
      showConfirmation: false,
      profilePictureBase64: "",

      file: undefined as Blob | undefined,

      // Card details
      code: "",
      firstName: "",
      lastName: "",
      organization: "",
      title: "",
      street: "",
      postalCode: "",
      city: "",
      countryRegion: "",
      workEmail: "",
      workPhone: "",
      workUrl: "",
      linkedInUrl: "",
      imageBase64: "",
      userId: "",
    };
  },
  async beforeMount() {
    try {
      if (isSignedIn()) {
        this.onboardingState = OnboardingState.EnteringCardCode;
      } else {
        this.onboardingState = OnboardingState.Unauthorized;
      }
    } catch (error) {
      console.error(error);
    }
  },
  async mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.onboardingState = OnboardingState.EnteringCardCode;
        this.userId = user.uid;
        console.log(`User ID: ${this.userId}`);
      } else {
        this.onboardingState = OnboardingState.Unauthorized;
        console.log("User is logged out");
      }
    });
  },
  components: {
    TextField,
    LoginTextField,
    SignUpView,
    ConfirmationAlert,
  },
  computed: {
    codeValid(): boolean {
      return this.code.length === 6;
    },
    profilePicture(): string | undefined {
      if (this.profilePictureBase64 === "") {
        return undefined;
      }
      return `data:image/jpeg;base64, ${this.profilePictureBase64}`;
    },
  },
  methods: {
    confirm() {
      window.scrollTo(0, 0);
      this.showConfirmation = true;
    },
    getErrorMessage(code: string): string {
      if (code === "card-not-found") {
        return "Kortet finns inte";
      } else if (code === "code-incorrect") {
        return "Fel kod, försök igen";
      } else if (code === "card-not-writable") {
        return "Kortet är inte överskrivbart";
      }
      return code;
    },
    async validateCode() {
      const code = this.code.toUpperCase();
      try {
        this.loading = true;
        const cardId = this.$route.params.cardId as string;
        await unlockCard(cardId, code);
        this.loading = false;
        this.wrong = false;
        window.scrollTo(0, 0);
        this.onboardingState = OnboardingState.EnteringCardDetails;
      } catch (error: any) {
        this.loading = false;
        this.errorMessage = this.getErrorMessage(error.details.errorCode);
        this.wrong = true;
      }
    },
    onFileSelected() {
      const fileEl: any = this.$refs["file"];
      const file = fileEl.files[0];
      console.log("File size:", file.size);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        const imageUrl = event.target.result;
        const image = document.createElement("img");
        image.src = imageUrl;
        image.onload = (e: any) => {
          const canvas = document.createElement("canvas");
          canvas.width = 120;
          canvas.height = 120;

          const context: any = canvas.getContext("2d");

          var hRatio = canvas.width / image.width;
          var vRatio = canvas.height / image.height;
          var ratio = Math.max(hRatio, vRatio);
          context.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            image.width * ratio,
            image.height * ratio
          );

          //context.drawImage(image, 0, 0, canvas.width, canvas.height);
          const croppedImageUrl = context.canvas.toDataURL("image/jpeg", 90);

          this.profilePictureBase64 = croppedImageUrl;
        };
      };
    },
    showProfilePictureInfo() {
      const dialog: any = document.getElementById("profile-picture-info-dialog");
      if (!dialog) { return; }
      dialog.showModal();
    },
    closeProfilePictureInfo() {
      const dialog: any = document.getElementById("profile-picture-info-dialog");
      if (!dialog) { return; }
      dialog.close();
    },
    async saveCardDetails() {
      this.showConfirmation = false;
      const code = this.code.toUpperCase();
      this.loading = true;
      const result = await updateBusinessCard({
        cardId: this.$route.params.cardId as string,
        code,
        firstName: this.firstName,
        lastName: this.lastName,
        organization: this.organization,
        title: this.title,
        workAddress: {
          street: this.street,
          postalCode: this.postalCode,
          city: this.city,
          countryRegion: "Sweden",
        },
        workEmail: this.workEmail,
        workPhone: this.workPhone,
        workUrl: this.workUrl,
        linkedInUrl: this.linkedInUrl,
        imageBase64: this.profilePictureBase64,
      });
      this.loading = false;
      window.scrollTo(0, 0);
      if (result) {
        this.onboardingState = OnboardingState.Finished;
      } else {
        this.onboardingState = OnboardingState.ErrorSaving;
      }
    },
    async signOut() {
      await logOut();
    },
  },
});
