import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bed1770"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-wrapper" }
const _hoisted_2 = { class: "alert-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.message), 1),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "primary-button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
        }, "Save")
      ]),
      _createElementVNode("div", null, [
        (_ctx.showBackButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "tertiary-button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
            }, " Tillbaka "))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}