
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String,
    },
    message: {
      required: true,
      type: String,
    },
    showBackButton: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  emits: ["cancel", "confirm"],
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
});
