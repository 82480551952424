
import { defineComponent } from "vue";
import { signUp, signIn } from "@/firebase";
// import TextField from "@/components/TextField.vue";
import LoginTextField from "@/components/LoginTextField.vue";

export default defineComponent({
  data() {
    return {
      // View related
      email: "",
      password: "",
      wrong: false,
      errorMessage: "",
      loading: false,
      loginValid: true,
    };
  },
  components: {
    // TextField,
    LoginTextField,
  },
  methods: {
    getErrorMessage(code: string): string {
      if (code === "card-not-found") {
        return "Kortet finns inte";
      } else if (code === "code-incorrect") {
        return "Fel kod, försök igen";
      } else if (code === "card-not-writable") {
        return "Kortet är inte överskrivbart";
      } else if (code === "auth/invalid-email") {
        return "Ogiltig mailadress";
      } else if (code === "auth/wrong-password") {
        return "Felaktigt lösenord";
      } else if (code === "auth/email-already-in-use") {
        return "E-postadressen finns redan registrerad";
      } else if (code === "auth/internal-error") {
        return "Okänt fel";
      }
      return code;
    },
    async onSignUp() {
      try {
        this.loading = true;
        await signUp(this.email, this.password);
        this.loading = false;
        this.wrong = false;
      } catch (error: any) {
        this.loading = false;
        this.errorMessage = this.getErrorMessage(error.code);
        this.wrong = true;
      }
    },
    async onSignIn() {
      try {
        this.loading = true;
        await signIn(this.email, this.password);
        this.loading = false;
        this.wrong = false;
      } catch (error: any) {
        this.loading = false;
        this.errorMessage = this.getErrorMessage(error.code);
        this.wrong = true;
      }
    },
  },
});
